
import {takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {CollectionOptionsInterface} from 'octopus-connect';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {DatacardService} from 'shared/datacard.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {OrderDirection, OctopusConnectService} from 'octopus-connect';
import {Subject} from 'rxjs';
import {AuthorizationService} from '@modules/authorization';
import {LessonsCardService} from '@modules/activities/core/lessons/services/lessons-card.service';

@Component({
    selector: 'app-lessons-created',
    templateUrl: './lessons-created.component.html'
})
export class LessonsCreatedComponent implements OnInit, OnDestroy {
    public dataCards: any[] = [];

    private optionsInterface: CollectionOptionsInterface;
    private type = 'byRole' as const;
    private unsubscribeInTakeUntil = new Subject<void>();

    constructor(
        public lessonsService: LessonsService,
        private activitiesService: ActivitiesService,
        private datacardService: DatacardService,
        private dialog: MatDialog,
        private octopusConnect: OctopusConnectService,
        private authorizationService: AuthorizationService,
        private lessonsCardService: LessonsCardService,
    ) {
        this.optionsInterface = {
            filter: {},
            orderOptions: [
                {field: 'created', direction: OrderDirection.DESC}
            ],
            page: 1,
            range: 6
        };
    }

    ngOnInit(): void {
        const callbacks = this.lessonsCardService.getCallbacks();

        this.lessonsService.loadPaginatedLessons(this.type, this.lessonsService.getAllowedRoleIdsForModelsCreation(), '', this.optionsInterface).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(resources => {
                if (!resources) {
                    return;
                }

                this.dataCards = this.datacardService.processResources(resources, callbacks, this.type);
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }




}
