import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {SharedModule} from 'shared/shared.module';
import {
    ActivityEditionTypologyIconComponent
} from './activity-edition-typology-icon/activity-edition-typology-icon.component';
import {
    ActivityEditionTypologyBlocComponent
} from './activity-edition-typology-bloc/activity-edition-typology-bloc.component';
import {ActivityEditionGridComponent} from './activity-edition-grid/activity-edition-grid.component';
import {
    ActivityEditorTextInputComponent
} from '@modules/activity-edition/core/ui/activity-editor-text-input/activity-editor-text-input.component';
import { ActivityEditionRadioButtonComponent } from './activity-edition-radio-button/activity-edition-radio-button.component';
import { ActivityEditionTrueFalseComponent } from './activity-edition-true-false/activity-edition-true-false.component';
import { ActivityEditionPointImgZoneGeneratorComponent } from './activity-edition-point-img-zone-generator/activity-edition-point-img-zone-generator.component';
import {
    ActivityEditionPointImgButtonSelectedElementComponent
} from '@modules/activity-edition/core/ui/activity-edition-point-img-button-selected-element/activity-edition-point-img-button-selected-element.component';
import { ActivityEditionPointImgGridComponent } from './activity-edition-point-img-grid/activity-edition-point-img-grid.component';
import { ActivityEditionPointImgUploadComponent } from '@modules/activity-edition/core/ui/activity-edition-point-img-upload/activity-edition-point-img-upload.component';
import { ActivityEditionPreviewWriteWordingComponent } from './activity-edition-preview-write-wording/activity-edition-preview-write-wording.component';
import { ActivityEditionPreviewWriteInstructionComponent } from './activity-edition-preview-write-instruction/activity-edition-preview-write-instruction.component';
import { ActivityEditionPreviewAddMediaComponent } from './activity-edition-preview-add-media/activity-edition-preview-add-media.component';
import { ActivityEditionPreviewWriteHelpFeedbackComponent } from './activity-edition-preview-write-help-feedback/activity-edition-preview-write-help-feedback.component';
import { ActivityEditionPreviewWriteBadFeedbackComponent } from './activity-edition-preview-write-bad-feedback/activity-edition-preview-write-bad-feedback.component';
import { ActivityEditionPreviewTrueFalseComponent } from './activity-edition-preview-true-false/activity-edition-preview-true-false.component';


@NgModule({
    declarations: [
        ActivityEditionTypologyIconComponent,
        ActivityEditionTypologyBlocComponent,
        ActivityEditionGridComponent,
        ActivityEditorTextInputComponent,
        ActivityEditionRadioButtonComponent,
        ActivityEditionTrueFalseComponent,
        ActivityEditionPointImgZoneGeneratorComponent,
        ActivityEditionPointImgButtonSelectedElementComponent,
        ActivityEditionPointImgGridComponent,
        ActivityEditionPointImgUploadComponent,
        ActivityEditionPreviewWriteWordingComponent,
        ActivityEditionPreviewWriteInstructionComponent,
        ActivityEditionPreviewAddMediaComponent,
        ActivityEditionPreviewWriteHelpFeedbackComponent,
        ActivityEditionPreviewWriteBadFeedbackComponent,
        ActivityEditionPreviewTrueFalseComponent,
    ],
    exports: [
        ActivityEditionTypologyBlocComponent,
        ActivityEditionGridComponent,
        ActivityEditorTextInputComponent,
        ActivityEditionRadioButtonComponent,
        ActivityEditionTrueFalseComponent,
        ActivityEditionTypologyIconComponent,
        ActivityEditionPointImgZoneGeneratorComponent,
        ActivityEditionPointImgButtonSelectedElementComponent,
        ActivityEditionPointImgUploadComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        FuseSharedModule,
    ]
})
export class UiModule {
}
