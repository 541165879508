import {of, ReplaySubject} from 'rxjs';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {DataEntity} from 'octopus-connect';
import {mergeMap} from 'rxjs/operators';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect/typed-data-entity.interface';
import {ActivityGranuleAttributes} from '@modules/activities/core/models/activities/activity-granule.attributes';
import {TypologyLabel} from '@modules/activities/core/models';
import {ActivityContent} from 'app/@modules/activities/core/models/activities/activity-contents';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html'
})

export class DialogComponent<T extends ActivityContent, U> implements OnInit, OnDestroy {
    public checkAnswer: boolean;
    public isLatexKeyboardDisplayed = false;
    public preview: boolean;
    // todo: better to create a ReplaySubject for  each activity type
    public refreshSubject: ReplaySubject<any> = new ReplaySubject(1);

    public currentActivity: TypedDataEntityInterface<ActivityGranuleAttributes<T, U>>;
    public settings: { [key: string]: any };

    private activities: TypedDataEntityInterface<ActivityGranuleAttributes<T, U>>[] = [];
    private activityParent: DataEntity;


    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
        this.preview = data.preview;
    }

    ngOnInit(): void {
        if (this.data.onLatexKeyboardDisplayChange) {
            this.data.onLatexKeyboardDisplayChange.subscribe((isDisplayed) => {
                this.isLatexKeyboardDisplayed = isDisplayed;
            });
        }

        if (this.data.activityDoesNotYetExist) {
            this.setData(this.data.activityDoesNotYetExist[0]);
            this.activities = this.data.activityDoesNotYetExist;
        } else {
            this.data.loadGranuleActivity(this.data.activityId)
                .pipe(
                    mergeMap((activity: DataEntity) => {
                        this.activityParent = activity;
                        if (this.templateParent === 'MULTIAC' || this.templateParent === 'EXT' && activity.get('format').label === 'lesson') {
                            return this.data.loadGranulesActivities(activity);
                        }
                        return of([activity]);
                    })
                ).subscribe((granuleActivities: TypedDataEntityInterface<ActivityGranuleAttributes<T, U>>[]) => {
                if (this.data.dataToEditActivities && granuleActivities.length) {
                    granuleActivities[0].get('metadatas').title = this.data.dataToEditActivities.title;
                    for (const act of granuleActivities) {
                        if (act && act.get('format').label === 'activity' && ![undefined, null, ''].includes(this.data.dataToEditActivities.instruction)) {
                            const field = act.get('metadatas').typology.label === TypologyLabel.multipleChoiceUniqueAnswer || act.get('metadatas').typology.label === 'summary' ? 'wording' : 'instruction';
                            act.get('reference')[field] = this.data.dataToEditActivities.instruction;
                        }
                    }
                }
                this.setData(granuleActivities[0]);
                return this.activities = granuleActivities;
            });
        }

        this.settings = this.data.settings;
    }

    ngOnDestroy(): void {
        this.data.clearLessonState(true);
    }

    /**
     * check if user can navigate to previous activity
     * @returns {boolean}
     */
    public get canGoToPrevious(): boolean {
        return this.indexInSublesson > 0;
    }

    /**
     * check if user can navigate to next activity
     * @returns {boolean}
     */
    public get canGoToNext(): boolean {
        return this.indexInSublesson < this.activities.length - 1;
    }

    /**
     * return current activity's index in sub lesson
     * @returns {number}
     */
    public get indexInSublesson(): number {
        return this.currentActivity && +this.activities
            .findIndex((activity) => +activity.id === +this.currentActivity.id);
    }

    /**
     * display navigate button for sub lesson
     */
    public get displayNavigation(): boolean {
        return this.activities.length > 1;
    }

    public get showBtnAction(): boolean {
        return this.currentActivity &&
            this.template !== 'MULTI' &&
            this.template !== 'IMGI' &&
            this.template !== 'EXT';
    }

    /**
     * navigate to previous activity in sub lesson
     */
    public launchPrevious(): void {
        if (this.canGoToPrevious === false) {
            return;
        }
        let currentActivityIndex = +this.indexInSublesson;
        currentActivityIndex--;
        this.setData(this.activities[currentActivityIndex]);
        this.refreshSubject.next(this.activities[currentActivityIndex]);
    }

    /**
     * navigate to next activity in sub lesson
     */
    public launchNext(): void {
        if (this.canGoToNext === false) {
            return;
        }
        let currentActivityIndex = +this.indexInSublesson;
        currentActivityIndex++;
        this.setData(this.activities[currentActivityIndex]);
        this.refreshSubject.next(this.activities[currentActivityIndex]);
    }

    /**
     * in case sublesson is launched from the activities list,
     * need to refresh the modal's data
     *
     */
    private setData(activity: TypedDataEntityInterface<ActivityGranuleAttributes<T, U>>): void {
        this.currentActivity = activity;
    }

    public get isMedia(): boolean {
        return this.template === 'audio' ||
            this.template === 'video' ||
            this.template === 'document' ||
            this.template === 'image' ||
            this.template === 'media' ||
            this.template === 'url' ||
            this.template === 'videoUrl';
    }

    /**
     * get typology of the current activity
     * @returns {string}
     */
    public get template(): string {
        if (this.currentActivity && this.currentActivity.get('ghost')) {
            return this.currentActivity.get('type') as string;
        }
        return this.currentActivity &&
            this.currentActivity.get('metadatas') &&
            this.currentActivity.get('metadatas').typology &&
            this.currentActivity.get('metadatas').typology.label;
    }

    /**
     * get typology of the parent activity (case sub lesson)
     * @returns {string}
     */
    public get templateParent(): string {
        return this.activityParent &&
        this.activityParent.get('metadatas') &&
        this.activityParent.get('metadatas').typology ?
            this.activityParent.get('metadatas').typology.label : null;
    }

    protected readonly TypologyLabel = TypologyLabel;
}
