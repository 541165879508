import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-activity-edition-preview-write-wording',
  templateUrl: './activity-edition-preview-write-wording.component.html',
  styleUrls: ['./activity-edition-preview-write-wording.component.scss']
})
export class ActivityEditionPreviewWriteWordingComponent {
    @Input() wording: string | null = null;
}
