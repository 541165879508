import {Component, inject, OnInit} from '@angular/core';
import {CompassService} from 'fuse-core/services/compass.service';
import {combineLatest, pipe} from 'rxjs';
import {DataEntity} from 'octopus-connect';
import {take, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models';
import * as _ from 'lodash-es';

@Component({
    selector: 'app-compass',
    templateUrl: './compass.component.html',
})
export class CompassComponent extends AutoUnsubscribeTakeUntilClass implements OnInit {

    private compassService = inject(CompassService);
    private router = inject(Router);
    private activatedRoute = inject(ActivatedRoute);
    public themes: DataEntity[] = [];
    public exploreData: DataEntity[] = [];
    public displayLoader = true;
    public navButtonClasses: string[] = ["bg-9342D2", "bg-5BC0EB", "bg-F2545B", "bg-5E2BFF", "bg-FE9920"];
    public compasNavButtonColorSet: string[] = ["compass-theme1", "compass-theme2", "compass-theme3", "compass-theme4", "compass-theme5", "compass-theme6"];
    public newsCardClasses: string[] = ["map-theme1", "map-theme2", "map-theme3", "map-theme4", "map-theme5"];

    constructor() {
        super();
    }

    ngOnInit() {
        const exploreData$ = this.compassService.loadExploreData()
            .pipe(
                take(1),
            );

        const themes$ = this.compassService.loadThemes()
            .pipe(
                take(1),
            );

        combineLatest([exploreData$, themes$])
            .pipe(
                take(1),
                tap(([exploreData, themes]) => {
                    this.exploreData = exploreData;
                    this.themes = themes;
                    this.displayLoader = false
                })
            )
            .subscribe();
    }

    /**
     * Get themes promoted
     */
    public get themesPromoted(): DataEntity[] {
        return this.themes.filter(theme => theme.get('promoted') === '1');
    }

    /**
     * cette fonction permet de voir les ressources d'un theme
     * @param idResources
     */
    public seeResources(theme: DataEntity): void {
        if (theme?.get('ressources')?.length > 0) {
            this.router.navigate(['boussole-ressources'],
                {
                    state: {
                        id: theme?.get('ressources'),
                        selectedThemeTitle: theme.get('label'),
                        selectedThemeBody: theme.get('body')
                    }
                });
        }
    }

    /**
     * cette fonction permet de voir les themes
     * @param theme
     */
    public seeThemes(theme: DataEntity): void {
        this.router.navigate(['boussole-themes'], {
            state: {
                diagnostics: theme.get('diagnostic'),
                skills: theme.get('skills'),
                difficulty: theme.get('difficulty'),
                title: theme.get('label'),
                peda: theme.get('peda'),
                orga: theme.get('orga'),
            }
        });
    }

}

