import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-activity-edition-preview-write-help-feedback',
  templateUrl: './activity-edition-preview-write-help-feedback.component.html',
  styleUrls: ['./activity-edition-preview-write-help-feedback.component.scss']
})
export class ActivityEditionPreviewWriteHelpFeedbackComponent {
    @Input() helpFeedback: string | null = null;
}
