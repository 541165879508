import {inject, Injectable} from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {
    CollectionOptionsInterface,
    DataEntity,
    OctopusConnectService,
    PaginatedCollection
} from 'octopus-connect';
import {map, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import * as _ from 'lodash';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect';
export enum banner {
    diagnostics = 'compass.banner_title_diagnostics',
    skills = 'compass.banner_title_skills',
    difficulty = 'compass.banner_title_difficulty',
    orga = 'compass.banner_title_organisation',
    peda = 'compass.banner_title_pedago',
}
@Injectable({
    providedIn: 'root'
})
export class CompassService {
    private communicationCenter = inject(CommunicationCenterService);
    private octopusConnect = inject(OctopusConnectService);
    constructor() { }

    /**
     * cette fonction permet de charger les themes
     * @param filterOptions
     */
    loadThemes(filterOptions?: CollectionOptionsInterface): Observable<DataEntity[]> {
        const defaultFilterOptions: CollectionOptionsInterface = {
            page: 1
        };
        const themesPaginated: PaginatedCollection = this.octopusConnect.paginatedLoadCollection('thematique-boussole', filterOptions ? _.merge(defaultFilterOptions, filterOptions) : defaultFilterOptions);
        const themesPaginatedObs: Observable<DataEntity[]> = themesPaginated.collectionObservable.pipe(map(collection => collection.entities));
        return themesPaginatedObs;
    }

    /**
     * cette fonction permet de charger les ressources
     * @param filterOptions
     */
    loadResources(filterOptions?: CollectionOptionsInterface): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('ressource-boussole', {id: filterOptions.filter.id})
            .pipe(map(collection => collection.entities));
    }

    /**
     * cette fonction permet de charger les niveaux educatifs de la boussole
     */
    public getEducationalLevels(): Observable<TypedDataEntityInterface<{}>[]> {
        return this.octopusConnect.loadCollection('level-boussole')
            .pipe(map(collection => <TypedDataEntityInterface<{}>[]> collection.entities));
    }
    public getDifficulties(): Observable<TypedDataEntityInterface<{}>[]> {
        return this.octopusConnect.loadCollection('difficulty-boussole')
            .pipe(map(collection => <TypedDataEntityInterface<{}>[]> collection.entities));
    }
    public getPedagoList(): Observable<TypedDataEntityInterface<{}>[]> {
        return this.octopusConnect.loadCollection('pedago')
            .pipe(map(collection => <TypedDataEntityInterface<{}>[]> collection.entities));
    }
    public getOrganisationList(): Observable<TypedDataEntityInterface<{}>[]> {
        return this.octopusConnect.loadCollection('organisation')
            .pipe(map(collection => <TypedDataEntityInterface<{}>[]> collection.entities));
    }

    /**
     * cette fonction permet de charger les donnees de l'exploration
     */
    public loadExploreData(): Observable<TypedDataEntityInterface<{}>[]> {
        return this.octopusConnect.loadCollection('explore')
            .pipe(map(collection => <TypedDataEntityInterface<{}>[]> collection.entities));
    }

    /**
     * cette fonction permet de charger les diagnostics
     */
    public loadDiagnostics(): Observable<TypedDataEntityInterface<{}>[]> {
        return this.octopusConnect.loadCollection('diagnostic')
            .pipe(map(collection => <TypedDataEntityInterface<{}>[]> collection.entities));
    }
    public loadSkills(): Observable<TypedDataEntityInterface<{}>[]> {
        return this.octopusConnect.loadCollection('skills-boussole')
            .pipe(map(collection => <TypedDataEntityInterface<{}>[]> collection.entities));
    }

    public loadResourceById(id: string): Observable<DataEntity> {
        return this.octopusConnect.loadEntity('ressource-boussole', id);
    }
}
