import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-activity-edition-true-false',
    templateUrl: './activity-edition-true-false.component.html',
    styleUrls: ['./activity-edition-true-false.component.scss']
})
export class ActivityEditionTrueFalseComponent implements OnChanges {
    @Input() control?: FormControl;
    @Input() disableRadioButton = false;// example for disable from parent [disableRadioButton]="!!state.ADD_ANSWER?.VF?.trueFalseAnswer"

    ngOnChanges(changes: SimpleChanges): void {
        this.disableRadioButton ? this.control.disable() : this.control.enable();
    }
}
