import {DynamicGraphFilters} from '@modules/graph-inclusive/core/model/dynamic-graph-filters';
import * as moment from 'moment';

export const OwnProgressFilters: DynamicGraphFilters = {
    always: [{
        label: 'startDate',
        value: moment().add(-2, 'weeks').startOf('day').toDate(),
        custom: {
            rules: ['required']
        }
    }, {
        label: 'endDate',
        value: moment().endOf('day').toDate(),
        custom: {
            rules: ['required']
        }
    }],
    hidden: [{
        label: 'concept',
        value: null,
        custom: {
            rules: ['autofill:multiLesson']
        }
    }, {
        label: 'chapter',
        value: null,
        custom: {
            rules: ['allowEmpty', 'autofill:multiLesson']
        }
    }, {
        label: 'multiLesson',
        value: null,
        custom: {
            rules: ['required']
        }
    }]
};
