import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';

@Injectable({
    providedIn: 'root'
})
export class GraphInclusiveTranslateService {

    private readonly keys: string[] = [
        'graph_inclusive.exercises',
        'graph_inclusive.total_time',
        'generic.pourcent_success',
        'graph_inclusive.progress_tooltip_header',
        'graph_inclusive.progress_good_first_percent',
        'graph_inclusive.progress_good_second_percent',
        'graph_inclusive.progress_bad_first_percent',
        'graph_inclusive.progress_bad_second_percent',
        'graph_inclusive.progress_good_first',
        'graph_inclusive.progress_good_second',
        'graph_inclusive.progress_bad_first',
        'graph_inclusive.progress_bad_second',
        'graph_inclusive.y_axis_legend',
        'graph_inclusive.number_of_attempts',
    ];

    private translations: {[key: string]: string} = {};

    constructor(
        private communicationCenter: CommunicationCenterService,
        private translate: TranslateService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((currentUser: DataEntity) => {
                if (!!currentUser) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.translate.onLangChange.subscribe(() => this.refreshKeys());
    }

    private postAuthentication(): void {
        this.refreshKeys();
    }

    private postLogout(): void {
        this.translations = {};
    }

    public get(key: string): string {
        return this.translations.hasOwnProperty(key) ? this.translations[key] : key;
    }

    private refreshKeys(): void {
        this.translate.get(this.keys).subscribe(data => {
            this.translations = data;
        });
    }
}
