import {ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {CardsService} from 'fuse-core/components/card/cards.service';
import {DataCardInterface} from 'fuse-core/components/card/data-card.interface';
import {DataEntity} from 'octopus-connect';
import {combineLatest, Observable, of} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {ContextualService} from '@modules/activities/core/services/contextual.service';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models';
import {map, filter, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {AuthorizationService} from '@modules/authorization';
import {AsyncRules} from '@modules/activities/core/models/rules';
import {LessonGranuleEntity} from '@modules/activities/core/models';
import {SharedEntitiesService} from '@modules/activities/core/services/shared-entities.service';
import {LessonsService} from '@modules/activities';
import {CommunicationCenterService} from '@modules/communication-center';

@Component({
    selector: 'app-lesson-page',
    templateUrl: './lesson-page.component.html'
})
export class LessonPageComponent extends AutoUnsubscribeTakeUntilClass implements OnInit {
    public datacard: DataCardInterface<LessonGranuleEntity>;
    public resource: LessonGranuleEntity;

    public customOptions: OwlOptions = {
        autoWidth: true,
        loop: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        navSpeed: 700,
        navText: ['<', '>'],
        nav: false,
    };

    public learners = [];
    public classes = [];
    public workgroups = [];

    public cardsService = inject(CardsService);
    private contextualService = inject(ContextualService);
    private ref = inject(ChangeDetectorRef);
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private authorizationService = inject(AuthorizationService);
    private sharedEntitiesService = inject(SharedEntitiesService);
    private lessonsService = inject(LessonsService);
    private communicationCenter= inject(CommunicationCenterService);
    private originUrl: string;

    public get filesCount(): number {
        return this.resource?.get('metadatas')?.files?.length;
    }

    public get collectionName(): string {
        return this.resource?.get('metadatas')?.indexation[0]?.label;
    }

    ngOnInit(): void {
        combineLatest([this.sharedEntitiesService.loadLearners(), this.sharedEntitiesService.loadClasses(), this.sharedEntitiesService.loadWorkgroups()])
            .pipe(
                tap(([learners, classes, workgroups]) => {
                    this.learners = learners;
                    this.classes = classes;
                    this.workgroups = workgroups;
                })
            )
            .subscribe();
        this.datacard = this.cardsService.currentDatacard;

        // if we don't have data, redirect to lessons/list/search to load our lesson and reopen it
        if (!this.datacard || +this.datacard.resource.id !== +this.route.snapshot.params['lessonId']) {
            this.router.navigate(
                ['lessons', 'list', 'search'],
                {
                    'queryParams':
                        {
                            'id': this.route.snapshot.params['lessonId'],
                            'openLessonPage': 1,
                            'origin-url': this.route.snapshot.queryParams['origin-url']
                        },
                    'skipLocationChange': true,
                });
        }

        if (this.datacard) {
            this.resource = this.datacard.resource;
            this.originUrl = this.route.snapshot.queryParams['origin-url'];
            this.router.navigate(
                [],
                {
                    relativeTo: this.route,
                    queryParams: {'origin-url': null},
                    queryParamsHandling: 'merge'
                });
        }
        this.cardsService.setTypeOfCard();
        this.ref.detectChanges();

        this.setupContextual();
    }

    // public hasPreviousPage(): boolean {
    //     return !!this.originUrl
    // }

    public goBackToPrevPage(): void {
        const url = this.originUrl
        const urlParams = this.getParamsFromURI(url);
        const queryParams = {};

        urlParams.forEach((value, key) => {
            if (key !== 'id' && key !== 'openLessonPage' && key !== 'origin-url') {
                queryParams[key] = value;
            }
        });

        this.router.navigate([this.removeAllURLParameters(url)], {queryParams});
    }

    public getParamsFromURI(relativeUrl) {
        const [, paramString] = relativeUrl.split('?');
        return new URLSearchParams(paramString);
    }

    public removeAllURLParameters(url) {
        //prefer to use l.search if you have a location/link object
        const urlparts = url.split('?');
        if (urlparts.length >= 2) {
            return urlparts[0];
        }
        return url;
    }

    public downloadDoc(uri: string): void {
        this.cardsService.downloadDoc(uri);
    }

    public isFavorite(): boolean {
        return this.cardsService.isFavorite;
    }

    public bookmark(): void {
        this.cardsService.bookmark();
    }

    public playPreview(): void {
        this.cardsService.playPreview();
    }

    public assignToLearners(): void {
        this.datacard.openAssign(this.datacard.resource);
    }

    public playSession(): void {
        // get type of modal to use old or new one
        this.communicationCenter
            .getRoom('assignment')
            .getSubject('view').pipe(take(1)).subscribe(comp => {
            if (this.cardsService.shouldSelectSubLesson()) {
                if (comp.name === 'AssignmentByStepsComponent') {
                    this.cardsService.play(<any>this.datacard);
                } else {
                    this.cardsService.openSubLessonSelectionModal()
                        .pipe(
                            take(1),
                            filter(subLessonId => !!subLessonId),
                            mergeMap((subLessonId) => this.lessonsService.loadLessonGranuleById(subLessonId).pipe(take(1))),
                            tap(subLesson => {
                                this.openAssignmentWithUserDataFormModal(subLesson, this.datacard);
                            })
                        )
                        .subscribe();
                }
            } else {
                if (comp.name === 'AssignmentByStepsComponent') {
                    // sans sous lesson nouvelle modal
                    this.cardsService.play(<any>this.datacard);
                } else {
                    // ici play sans sous lessons ancienne modal
                    this.openAssignmentWithUserDataFormModal(this.resource, this.datacard);
                }
            }
        });
    }

    private openAssignmentWithUserDataFormModal(entity: DataEntity, datacard: DataCardInterface<LessonGranuleEntity>): void {
        this.cardsService.openAssignmentWithUserDataFormModal(entity, datacard, this.learners, this.classes, this.workgroups);
    }

    // TODO Refacto pour utiliser le authorizationService (c'est au module assignation de définir règles et de les exposer au niveau de authorizationService
    public isAssignable(): boolean {
        // hide button for usage.collectif only lesson
        return this.resource?.get('usage').some(e => e.label === 'usage.autonomie');
        // return this.cardsService.displayField('assignIcon') ? this.datacard.isAssignable$ : of(false);
    }

    public isEditable(): Observable<boolean> {
        return this.authorizationService.currentUserCan<Observable<boolean>>(AsyncRules.EditLesson, of(false), this.resource);
    }

    public isDuplicableAndEditable(): Observable<boolean> {
        return combineLatest([
            this.authorizationService.currentUserCan<Observable<boolean>>(AsyncRules.EditLesson, of(false)),
            this.authorizationService.currentUserCan<Observable<boolean>>(AsyncRules.DuplicateLesson, of(false), this.resource)
        ]).pipe(
            map(([canEdit, canDuplicateThisLesson]) => canEdit && canDuplicateThisLesson)
        );
    }

    private setupContextual(): void {
        this.contextualService.actionLessonPreview$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(() => this.playPreview());
        this.contextualService.actionLessonPlay$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(() => this.playSession());
        this.contextualService.actionLessonOpenAssign$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(() => this.assignToLearners());

        this.contextualService.conditionLessonHasDocument$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(this.filesCount > 0));
        this.contextualService.conditionLessonAssignable$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(this.isAssignable()));

        this.contextualService.dataLessonDocumentCount$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(this.filesCount.toString()));
        this.contextualService.dataLessonCollectionName$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(this.collectionName));
    }

    public onDeleteButtonClick($event: MouseEvent): void {
        $event.stopPropagation();
        this.datacard.deleteWithAssignments$(this.resource).subscribe(() => {
            this.goBackToPrevPage();
        });
    }


    private isModel(): boolean {
        return this.resource?.get('model');
    }

    public showEditButton(): Observable<boolean> {
        const type = this.isModel() ? 'model' : 'lesson';
        const allowedByConfig = this.cardsService.displayMenuLink('edit', type);
        if (!allowedByConfig) {
            return of(false);
        }
        return this.isEditable();
    }

    public showDuplicateAndEditButton(): Observable<boolean> {
        const type = this.isModel() ? 'model' : 'lesson';
        const allowedByConfig = this.cardsService.displayMenuLink('duplicateAndEdit', type);
        if (!allowedByConfig) {
            return of(false);
        }
        return combineLatest([this.isEditable(), this.isDuplicableAndEditable()]).pipe(
            map(([isEditable, isDuplicableAndEditable]) => (isEditable === false) && isDuplicableAndEditable)
        );
    }

    public showDuplicateIfNeededAndEditButton(): Observable<boolean> {
        const type = this.isModel() ? 'model' : 'lesson';
        const allowedByConfig = this.cardsService.displayMenuLink('duplicateIfNeededAndEdit', type);

        if (!allowedByConfig) {
            return of(false);
        }
        return combineLatest([this.isEditable(), this.isDuplicableAndEditable()]).pipe(
            map(([isEditable, isDuplicableAndEditable]) => isEditable || isDuplicableAndEditable)
        );
    }
}
