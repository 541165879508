import {
    environment,
    getTrackingID, getTrackingIDGAds, getTrackingIDGTM,
    getTrackingIDMatomo, getTrackingIDMetaPixel, getTrackingIDSnapchat,
} from '../../../environments/environment';

import { LayoutConfig } from 'fuse-core/types';
import { RecursivePartial } from 'shared/utils';
import { Roles } from 'shared/models/roles';
import { accountManagement } from './modules/account-management';
import { achievements } from './modules/achievements';
import { activities } from './modules/activities';
import { assignation } from './modules/assignation';
import { authentication } from './modules/authentication';
import { basicPage } from './modules/basic-page';
import { browserTest } from './modules/browser-test';
import { cards } from './core/cards';
import { configuration } from './modules/configuration';
import { corpus } from './modules/corpus';
import { dashboard } from './modules/dashboard';
import { faq } from './modules/faq';
import { featureAccess } from './core/feature-access';
import { gamecode } from './modules/gamecode';
import { gamification } from './modules/gamification';
import { graphAssignation } from './modules/graph-assignation';
import {graphMulti} from './modules/graph-multi';
import { groupsManagement } from './modules/groups-management';
import { header } from './core/header';
import { homePage } from './modules/home-page';
import { ideasWall } from './modules/ideas-wall';
import { licensing } from './modules/licensing';
import { lrs } from './modules/lrs';
import { mainMenu } from './core/menu';
import { notification } from './modules/notification';
import { projectsManagement } from './modules/projects-management';
import { researchSheet } from './modules/research-sheet';
import {routesAccess} from "./core/routes-access";
import { timeline } from './modules/timeline';
import {mindmap} from './modules/mindmap';
import {notepad} from "./modules/notepad";
import {contest} from "./modules/contest";
import {infoLegales} from './core/info-legales';

export const defaultValue = {};

export const brand = 'humanum'; // instance name of repository project, asset floder name, settings ...
export const defaultMetaDescription = null;
export const brandTitle = 'ILE&Co'; // index.html title tab title
// 'recaptcha' (google) or 'hcaptcha' or 'friendlyCaptcha'. Hcaptcha not working on localhost, use test.mydomain.com instead => (ng serve --host test.mydomain.com)
export const captcha = 'friendlyCaptcha';
export const GATracking = false; // allow or not Google Analytics tracking
export const matomoTracking = true; // allow or not matomo tracking
export const accessibiActivate = false; // launch accessibe
export const youTubeTracking = false; // allow or not youtube tracking
export const twitterTracking = false; // allow or not twitter tracking
export const matomoTrackingID = '38'; // à changer, ID matomo de la prod de l'instance
export const GAdsTracking = false; // allow or not Google Ads tracking
export const GTMTracking = false; // allow or not Google Tag Manager tracking
export const MetaPixelTracking = false; // allow or not Meta pixel tracking
export const SnapchatTracking = false; // allow or not Google Analytics tracking
export const GTMTrackingID = ''; // à changer, ID matomo de la prod de l'instance
export const MetaPixelTrackingID = ''; // à changer, ID de la prod de l'instance
export const SnapchatTrackingID = ''; // à changer, ID de la prod de l'instance
export const GAdsTrackingID = ''; // à changer, ID de la prod de l'instance
export const GAdsTrackingIDFromSettings =
    getTrackingIDGAds(GAdsTrackingID);
export const GTMTrackingIDFromSettings =
    getTrackingIDGTM(GTMTrackingID);
export const MetaPixelTrackingIDFromSettings =
    getTrackingIDMetaPixel(MetaPixelTrackingID);
export const SnapchatTrackingIDFromSettings =
    getTrackingIDSnapchat(SnapchatTrackingID);
export const matomoTrackingIDFromSettings =
    getTrackingIDMatomo(matomoTrackingID);
export const trackingID = '';
export const trackingIDFromSettings = getTrackingID(trackingID);
export const LRSTracking = false; // allow or not LRS tracking
export const LRSTrackingUrl = '';
export const useBrandTwoDifferentSvg = false; // use two svg one for menu open and one for close and logo is different from those use in login menu
export const brandLogoSvg = false;
export const baseAppUrl = environment.baseAppUrl(brand);
export const defaultApiURL = environment.baseApiUrl(brand);
export const defaultNodeURL = environment.baseNodeUrl(brand);
export const defaultURL = environment.baseUrl(brand);
export const defaultLoginRoute = '/home';
export const defaultRoute = '/home';
export const defaultLanguage = 'fr';
export const filterByCurrentYearByDefault = false;
export const addRoleLicenceSuffixForExternalLink = false;
export const langs = [
    {
        id: 'fr',
        title: 'Français',
        flag: 'fr',
        code: 'fr-FR',
    },
];
export const mobileUrlRedirection = null;
export const isMobileAlert = true;
export const dateTimeOptions: Intl.DateTimeFormatOptions = {
    weekday: undefined,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
};
export const tralalereBar = {
    displayed: false,
};
export const displayHelp = false;

export const displayHeader = false;

export const displayHeaderTitle = false;

export const customHomePageBanner = false;

export const titleTextInHomePageBanner = false;

export const cardLayout = 'card-split-media-info';

export const enableRgpdLinks = true;

export const customLayoutConfig: RecursivePartial<{
    [key in Roles | 'default']: LayoutConfig;
}> = {};

export const globalNewsSettings = {};

export const modulesSettings = {
    accountManagement,
    activities,
    assignation,
    authentication,
    basicPage,
    browserTest,
    cards,
    contest,
    corpus,
    dashboard,
    featureAccess,
    gamecode,
    gamification,
    graphAssignation,
    graphMulti,
    groupsManagement,
    homePage,
    ideasWall,
    licensing,
    mainMenu,
    notification,
    projectsManagement,
    researchSheet,
    routesAccess,
    configuration,
    timeline,
    header,
    lrs,
    notepad,
    achievements,
    faq,
    mindmap,
    infoLegales,
};

export const externalUrl = null;

// https://tarteaucitron.io/fr/
export const tarteaucitronConf: object = {
    "privacyUrl": "", /* Privacy policy url */
    "bodyPosition": "bottom", /* or top to bring it as first element for accessibility */
    "hashtag": "#tarteaucitron", /* Open the panel with this hashtag */
    "cookieName": "tarteaucitron", /* Cookie name */
    "orientation": "popup", /* Banner position (top - bottom - middle - popup) */
    "groupServices": false, /* Group services by category */
    "showDetailsOnClick": true, /* Click to expand the description */
    "serviceDefaultState": "wait", /* Default state (true - wait - false) */
    "showAlertSmall": false, /* Show the small banner on bottom right */
    "cookieslist": false, /* Show the cookie list */
    "showIcon": true, /* Show cookie icon to manage cookies */
    "iconSrc": "https://www.tralalere.com/header/images/cookies.svg",
    "iconPosition": "BottomRight", /* Position of the icon between BottomRight, BottomLeft, TopRight and TopLeft */
    "adblocker": false, /* Show a Warning if an adblocker is detected */
    "DenyAllCta" : true, /* Show the deny all button */
    "AcceptAllCta" : true, /* Show the accept all button when highPrivacy on */
    "highPrivacy": true, /* HIGHLY RECOMMANDED Disable auto consent */
    "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */
    "removeCredit": false, /* Remove credit link */
    "moreInfoLink": true, /* Show more info link */
    "useExternalCss": false, /* If false, the tarteaucitron.css file will be loaded */
    "useExternalJs": false, /* If false, the tarteaucitron.services.js file will be loaded */
    // "cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for subdomain website */
    "readmoreLink": "https://www.tralalere.com/page/rgpd", /* Change the default readmore link pointing to tarteaucitron.io */
    "mandatory": false, /* Show a message about mandatory cookies */
    "mandatoryCta": true, /* Show the disabled accept button when mandatory on */
    // "customCloserId": "", /* Optional a11y: Custom element ID used to open the panel */
    "googleConsentMode": true /* Enable Google Consent Mode v2 for Google ads and GA4 */
};

export * from './core/fuse-config';
