import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { tap, shareReplay, catchError } from 'rxjs/operators';
import { ActivitiesService } from '@modules/activities/core/activities.service';
import { ActivityGranule } from '@modules/activities/core/models';

@Injectable({
    providedIn: 'root'
})
export class ActivityRepositoryService {
    private cache = {};
    private inFlightRequests: { [key: string]: Subject<ActivityGranule> } = {};

    constructor(private activitiesService: ActivitiesService) {}

    getActivity(id: number | string): Observable<ActivityGranule> {
        if (this.cache[id]) {
            return of(this.cache[id]);
        } else if (this.inFlightRequests[id]) {
            return this.inFlightRequests[id].asObservable();
        } else {
            const subject = new Subject<ActivityGranule>();
            this.inFlightRequests[id] = subject;
            this.activitiesService.loadActivitiesFromId(id).pipe(
                tap(activity => {
                    this.cache[id] = activity;
                    subject.next(activity);
                    subject.complete();
                    delete this.inFlightRequests[id];
                }),
                catchError(err => {
                    subject.error(err);
                    delete this.inFlightRequests[id];
                    return of(null);
                }),
                shareReplay(1)
            ).subscribe();
            return subject.asObservable();
        }
    }
}
