import {mergeMap, take} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {combineLatest, Observable} from 'rxjs';
import {CommunicationCenterService} from '@modules/communication-center';
import {MediaInterface} from '@modules/activities/core/lessons/lessons-interfaces.interface';
import {TypologyLabel} from '@modules/activities/core/models';

const limitPollNumber = 5;

@Component({
    selector: 'app-short-answer-multi-editor',
    templateUrl: './short-answer-multi-editor.component.html'
})
export class ShortAnswerMultiEditorComponent implements OnInit {
    public activityStepIndex = 0;
    public formBuilder: UntypedFormBuilder = new UntypedFormBuilder();
    public formArray: any[] = [];
    public maxValue: number = 100;
    public minValue: number = 0;
    public activitySummary: DataEntity;
    public hidePlaceholder: boolean;
    public showSpinner: boolean;

    private params: any;
    private sublessonContent: DataEntity[] = [];
    private sliderValues: object = {};
    private activityAndActivitiesDuplicated = [];
    private limitPollNumber: number;
    private lessonsDuplicatedContent: any[] = [];
    private isGoToLastActivity: boolean;

    constructor(public lessonsService: LessonsService,
                public activitiesService: ActivitiesService,
                private router: Router,
                private route: ActivatedRoute,
                private communicationCenter: CommunicationCenterService,
                private octopusConnect: OctopusConnectService) {
    }

    public get questionIndex(): string {
        return this.activityStepIndex + 1 + ' / ' + this.formArray.length;
    }

    public get activityLocked(): boolean {
        return this.lessonsService.settings.lessonStep &&
            this.settings &&
            this.settings.dataFromStep &&
            this.settings.dataFromStep.readOnly;

    }

    private get settings(): any {
        if (this.lessonsService.settings && this.lessonsService.settings.lessonStep) {
            return this.lessonsService.settings.lessonStep.typeSteps[this.params.stepIndex];
        }

        return null;
    }

    ngOnInit(): any {
        this.activityStepIndex = 0;
        this.communicationCenter
            .getRoom('activities')
            .getSubject('lessonEditedOver')
            .subscribe((val) => {
                if (val) {
                    this.initialize();
                }
            });
        this.route.queryParams.subscribe(params => {
            this.params = params;
        });

        if (this.params) {
            this.hidePlaceholder = this.lessonsService.settings.lessonStep.typeSteps[this.params.stepIndex] &&
                !!this.lessonsService.settings.lessonStep.typeSteps[this.params.stepIndex].dataFromStep ||
                this.lessonsService.settings.lessonStep.typeSteps[this.params.stepIndex].dataFromStep === 0;
        }

        this.router.navigate(['lessons', 'list']);

    }

    public addPoll(): void {
        if (this.formArray.length < this.limitPollNumber) {
            this.formArray.push(this.createEntityForm());
            this.saveActivity('add')
                .subscribe(() => {
                    this.isGoToLastActivity = true;
                    this.refreshLesson();
                });
        }
    }

    public removePoll(): void {
        if (this.activityStepIndex > 0 && this.formArray.length > 1) {
            this.saveActivity('remove')
                .subscribe(() => {
                    this.isGoToLastActivity = true;
                    this.refreshLesson();
                });
        }
    }

    public previousPoll(): any {
        if (this.activityStepIndex > 0) {
            this.activityStepIndex = +this.activityStepIndex - 1;
        }
    }

    public nextPoll(): any {
        if (this.activityStepIndex < this.formArray.length - 1) {
            this.activityStepIndex = +this.activityStepIndex + 1;
        }
    }

    public save(): void {
        if (!this.showSpinner) {
            this.saveActivity()
                .subscribe(() => {
                    this.refreshLesson();
                });
        }
    }

    public exit(): void {
        this.router.navigate(['lessons', 'list']);
    }

    /**
     * emit to the parent the position when moving cursor
     * @param evt : position du curseur sur la frise.
     */
    public sliderMove(evt: any): void {
        this.sliderValues[this.activityStepIndex] = +evt.currentTarget.value;
    }

    /**
     * emit to the parent the position after click
     * @param evt : position du curseur sur la frise.
     */
    public sliderClick(evt: any): void {
        this.sliderValues[this.activityStepIndex] = +evt.currentTarget.value;
    }

    private initialize(): void {
        this.showSpinner = true;
        this.refreshActivity();
    }

    private refreshActivity(): void {
        this.activitiesService
            .setActivitiesAfterLoaded(this.lessonsService.getCurrentActivity().get('reference'), false).pipe(
            take(1))
            .subscribe((entities: DataEntity[]) => {
                this.activitySummary = entities.find((entity) => entity.get('metadatas').typology.label === 'summary');
                if (this.activitySummary && typeof this.activitySummary.get('reference') === 'string') {
                    const activitiesId = entities
                        .filter((activity) => +activity.id !== +this.activitySummary.id)
                        .map((activitiesPoll) => activitiesPoll.id);

                    this.createSummaryAndPatchRef(activitiesId, this.activitySummary)
                        .subscribe((entity: DataEntity) => {
                            // subscribe for trigger return of combineLatest
                            this.refreshLesson();
                        });
                } else {
                    this.activityAndActivitiesDuplicated = [this.lessonsService.getCurrentActivity()];

                    const obsLessons = this.activityAndActivitiesDuplicated
                        .filter((lesson) => lesson.id !== this.lessonsService.getCurrentActivity().id)
                        .map((lessonFiltered: DataEntity) => {
                            return this.activitiesService.setActivitiesAfterLoaded(lessonFiltered.get('reference'), false).pipe(
                                take(1));
                        });

                    if (obsLessons.length > 0) {
                        combineLatest(...obsLessons)
                            .subscribe((activities: any[]) => {
                                this.lessonsDuplicatedContent = [];
                                this.lessonsDuplicatedContent.push(entities, ...activities);

                                const summaries = this.lessonsDuplicatedContent
                                    .map((lessonContent) => {
                                        return lessonContent.find((activity) => activity.get('metadatas').typology.label === 'summary');
                                    })
                                    .filter((activity) => !!activity);

                                if (summaries.length > 0) {
                                    combineLatest(...this.editSummaryActivity(entities, summaries))
                                        .subscribe(() => {
                                            this.generateForm(entities);
                                        });
                                } else {
                                    this.generateForm(entities);
                                }
                            });
                    } else {
                        this.generateForm(entities);
                    }

                }
            });
    }

    private generateForm(entities): void {
        this.formArray = [];
        this.sublessonContent = entities.filter((entity) => entity.get('metadatas').typology.label !== TypologyLabel.summary);
        this.sublessonContent.forEach((val: DataEntity, key) => {

            this.formArray.push(this.createEntityForm(val));

            if (this.activityLocked) {
                this.formArray[key].disable();
            }
        });

        if (this.isGoToLastActivity) {
            this.activityStepIndex = this.formArray.length - 1;
        }
        this.isGoToLastActivity = false;
        this.limitPollNumber = this.settings ? this.settings.limit : limitPollNumber;
        this.showSpinner = false;
    }

    private refreshLesson(): void {
        this.activitiesService.loadActivitiesFromId(this.params.activityId.toString()).pipe(
            take(1))
            .subscribe((entity: DataEntity) => {
                this.lessonsService.setCurrentActivity(entity);
                this.initialize();
            });
    }

    private createEntityForm(entity: DataEntity = null): UntypedFormGroup {

        const config: any = {
            answers0: ['']
        };

        config.instruction = [entity && entity.get('reference').instruction ? entity.get('reference').instruction : ''];
        config.slider = [{value: 0, disabled: true}];

        if (entity) {
            entity.get('reference').activity_content.answers.forEach((answer, index) => {
                config[`answers${index}`] = [answer];
            });
        }

        return this.formBuilder.group(config);
    }

    private createSummaryAndPatchRef(activitiesId, summary): Observable<DataEntity> {

        const summaryData: MediaInterface = {
            granule: activitiesId
        };
        const obsSummary = this.lessonsService.createSummary(summaryData);
        const obsRef = this.activitiesService.loadActivityInterface(summary.get('reference'));

        return combineLatest(obsSummary, obsRef).pipe(
            mergeMap((summaryAndRef: DataEntity[]) => {
                summaryAndRef[1].set('activity_content_patch', summaryAndRef[0].id);
                return summaryAndRef[1].save();
            }));
    }

    private saveActivity(action = null): Observable<any> {
        this.showSpinner = true;
        const obsArray = [];
        const refEntityEditedObs = [];
        const refs = [];

        if (action === 'remove') {
            if (this.sublessonContent[this.activityStepIndex]) {
                this.sublessonContent.splice(this.activityStepIndex, 1);
                this.activityStepIndex = this.activityStepIndex - 1; // formGroup will trow an error because formArray[this.activityStepIndex] will be undefined
                this.formArray.splice(this.activityStepIndex + 1, 1);
            }
        }
        this.formArray.forEach((val, key) => {
            if (this.sublessonContent[key]) {

                refs.push(
                    new DataEntity(
                        'activity',
                        this.sublessonContent[key].get('reference'),
                        this.octopusConnect,
                        this.sublessonContent[key].get('reference').id)
                );

            } else {
                const config = this.settings ? this.settings.config : null;
                obsArray.push(this.lessonsService.createActivityQcm('CRT', {
                    wording: val.value.instruction,
                    answers: ['']
                }, config));
            }
        });

        refs.forEach((val: DataEntity, key) => {
            val.set('instruction', this.formArray[key].value.instruction);
            refEntityEditedObs.push(val.save());
        });

        return combineLatest(...refEntityEditedObs).pipe(
            mergeMap((entities: DataEntity[]) => {
                entities.forEach((val: DataEntity, key) => {
                    val.set('instruction', this.formArray[key].value.instruction);
                    refEntityEditedObs.push(val.save());
                });

                return combineLatest(...refEntityEditedObs, ...obsArray).pipe(
                    mergeMap((savedEntities: DataEntity[]) => {
                        const summaries = [];
                        if (this.activitySummary) {
                            summaries.push(this.activitySummary);
                        }
                        const granuleActivityId = savedEntities
                            .filter((entity: DataEntity) => entity.get('format') && entity.get('format').label === 'activity')
                            .map((val: DataEntity) => val.id);

                        const lessonsObs = this.activityAndActivitiesDuplicated.map((entity, index) => {
                            const lesson =
                                new DataEntity(
                                    'lesson',
                                    {lesson_step: entity.get('reference')},
                                    this.octopusConnect,
                                    entity.get('lesson_id'));

                            const lesson_step = this.sublessonContent.map((activity: DataEntity) => activity.id).concat(granuleActivityId);
                            const summmary = this.lessonsDuplicatedContent[index] ? this.lessonsDuplicatedContent[index].find((activity) => activity.get('metadatas').typology.label === 'summary') : null;

                            if (this.activitySummary &&
                                entity.get('reference').some((activity) => +activity.id === +this.activitySummary.id) ||
                                !!summmary) {
                                lesson_step.push(this.activitySummary ? this.activitySummary.id : summmary.id);
                            }

                            if (summmary) {
                                summaries.push(summmary);
                            }
                            lesson.set('lesson_step', lesson_step);

                            return lesson.save();

                        });

                        if (summaries.length > 0) {
                            return combineLatest(...lessonsObs).pipe(
                                mergeMap((lessonEntities: DataEntity[]) => {
                                    return combineLatest(...this.editSummaryActivity(lessonEntities[0].get('lesson_step'), summaries));
                                }));
                        } else {
                            return combineLatest(...lessonsObs);
                        }
                    }));
            }));
    }

    private editSummaryActivity(activities, summaries): Observable<DataEntity>[] {
        const obsSummaries = summaries.map((summaryActivity) => {
            const activitiesId = activities
                .filter((activity) => +activity.id !== +summaryActivity.id)
                .map((activitiesPoll) => activitiesPoll.id);

            if (typeof summaryActivity.get('reference') === 'object') {
                const summary = new DataEntity(
                    'summary',
                    summaryActivity.get('reference').activity_content[0],
                    this.octopusConnect,
                    summaryActivity.get('reference').activity_content[0].id
                );

                summary.set('granule', activitiesId);
                return summary.save();
            } else {
                return this.createSummaryAndPatchRef(activitiesId, summaryActivity);
            }
        });

        return obsSummaries;
    }

}
