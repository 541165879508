import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {DataCollection, OctopusConnectService} from 'octopus-connect';
import {brand, cardLayout} from 'app/settings';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../app/settings';
import {map} from 'rxjs/operators';
import {UserProgression} from 'fuse-core/components/home-page/home-page.component';
const settingsStructure: ModelSchema = new ModelSchema({
    urlVideoException: Structures.array([]),
    isProgressionEnable: Structures.boolean(),
    concepts: Structures.array()
});

@Injectable({
    providedIn: 'root'
})

export class HomePageService {
    public cardLayout = cardLayout;
    public settings: { [key: string]: any };

    constructor(private octopusConnect: OctopusConnectService) {
        this.settings = settingsStructure.filterModel(modulesSettings.homePage);
    }

    public loadHomePage(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('homepage_lms', {instance_name: brand});
    }

    public loadUserProgression(userProgressFilter: {
        educationalLevel: string,
        concept: string,
    }): Observable<UserProgression> {
        return this.octopusConnect.loadCollection('user-progress', userProgressFilter).pipe(
            map((collection) => collection.entities[0].get('currentUser'))
        );
    }
}
