import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {DataEntity} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect';
import {ActivityGranule, ActivityGranuleAttributes} from '@modules/activities/core/models/activities/activity-granule.attributes';
import {DrawLineService} from '@modules/activities/core/services/draw-line.service';
import {AnswerInterface} from '@modules/activities/core/models/answer.interface';
import {environment} from 'environments/environment';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {ActivatedRoute} from '@angular/router';
import {AnswerResultInterface, TypologyLabel} from '@modules/activities/core/models';
import {AuthenticationService} from '@modules/authentication';
import {ContextualService} from '@modules/activities/core/services/contextual.service';
import {takeUntil} from 'rxjs/operators';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models';
import {DrawLineActivityGranule} from '@modules/activities/core/models/activities/typologies/draw-line-activity.granule';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';

const ACTIVITIES_WITHOUT_CORRECTION: TypologyLabel[] = [
    TypologyLabel.divider,
    TypologyLabel.audio,
    TypologyLabel.external,
    TypologyLabel.image,
    TypologyLabel.interactiveImage,
    TypologyLabel.tool,
    TypologyLabel.summary,
    TypologyLabel.video,
    TypologyLabel.recording,
    TypologyLabel.multimedia,
    TypologyLabel.awareness,
    TypologyLabel.memory,
    TypologyLabel.imageSoundZone,
];

const GOOD_FEEDBACK_THRESHOLD = 6;

@Component({
    selector: 'app-summary-sub-lesson',
    templateUrl: './summary-sub-lesson.component.html',
    styleUrls: ['./summary-sub-lesson.component.scss'],
})
export class SummarySubLessonComponent extends AutoUnsubscribeTakeUntilClass implements OnInit, OnDestroy {

    protected readonly TypologyLabel = TypologyLabel;

    private activitiesService = inject(ActivitiesService)
    private activatedRoute = inject(ActivatedRoute)
    private lessonsService = inject(LessonsService)
    private contextualService = inject(ContextualService)
    private communicationCenter = inject(CommunicationCenterService)
    private drawLineService = inject(DrawLineService)
    private authenticationService = inject(AuthenticationService)
    private config = inject(LessonsConfigurationService)

    public panelOpenState = false;
    public shouldDisplayGradeBlock = this.config.displayGradeBlockInSummarySubLesson();
    public shouldDisplayDetailsBlock = this.config.displayDetailsBlockInSummarySubLesson();
    public showBasePlatformPathButton = this.config.showBasePlatformPathButton();

    public activities: ActivityGranule[] = [];
    public basePlatformPath: string = environment.basePlatformPath;

    constructor() {
        super();

        this.panelOpenState = this.authenticationService.isLearner();

        this.communicationCenter
            .getRoom('stepper')
            .getSubject('display')
            .next(false);
    }

    public get assignmentId(): string {
        return this.lessonsService.currentAssignment?.id.toString();
    }

    public get isMyAssignment(): boolean {
        return !this.lessonsService.currentAssignment
            || (!!this.lessonsService.currentAssignment.get('assignated_user')?.id
                && this.authenticationService.isMe(this.lessonsService.currentAssignment.get('assignated_user').id));
    }

    /**
     * savoir si on a une activité suivante pour afficher le bouton "next"
     */
    public get isNextActivity(): boolean {
        return !!this.lessonsService.currentLesson?.get('reference')[this.activitiesService.presentArrayElementIndex + 1];
    }

    private get nextActivityName(): string {
        return this.lessonsService.currentLesson?.get('reference')[this.activitiesService.presentArrayElementIndex + 1]?.title;
    }

    public get displayRetryBtn(): boolean {
        return this.config.displayBtnRetryInSummarySubLesson && !this.activatedRoute.snapshot?.queryParams?.navigateDirectlyToSummary;
    }

    ngOnInit(): void {
        this.communicationCenter.getRoom('header-exo').next('show', false);
        this.communicationCenter.getRoom('progress-bar-exo').next('hide', true);
        this.communicationCenter.getRoom('footer').next('visibility', false);
        this.initialize();
        this.setupContextual();
        // scroll on top to ever had summary title on top
        document.getElementsByTagName('app-summary-sub-lesson')[0].scrollTo(0, 0);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();

        this.communicationCenter.getRoom('progress-bar-exo').next('reset', false);
        this.communicationCenter
            .getRoom('activities')
            .next('retryCurrentActivity', null);
        this.communicationCenter.getRoom('activities').next('nextActivity', null);
        this.communicationCenter.getRoom('activities').next('backToLesson', false);
        this.communicationCenter.getRoom('header-exo').next('show', true);
        this.communicationCenter.getRoom('progress-bar-exo').next('hide', false);
        this.communicationCenter.getRoom('footer').next('visibility', true);
        this.communicationCenter.getRoom('stepper').next('display', true);
        document.getElementsByTagName('body')[0].classList.remove('summary');
    }

    initialize(): void {
        this.addSummaryClassToBody();
        this.activities = this.getActivities();

        // hotfix, should be fix in higher level
        this.activities = this.removeDuplicates(this.activities);

        this.lessonsService.updateCurrentLessonState();

        if (this.lessonsService.settings.activitiesBroadcastLifeCycle) {
            const params = this.activatedRoute.pathFromRoot.reduce((params, route) => ({...params, ...route.snapshot.params}), {});
            const lessonId = params['lessonId'];

            const queryParams = this.activatedRoute.pathFromRoot.reduce((params, route) => ({...params, ...route.snapshot.queryParams}), {});
            const subLessonId = queryParams['subLessonId'];

            const target = subLessonId ?? lessonId;

            const score = this.lessonsService.currentAssignment ? this.lessonsService.calculateAndSaveQuestionSetProgress(target) : 0;

            const statementData = {
                id: `questionSet/${target}`,
                result: {
                    score: {
                        scaled: score / 100,
                    },
                    completion: true
                }
            };

            this.communicationCenter.getRoom('lrs').getSubject('activity_complete').next(statementData);
        }
    }

    private setupContextual(): void {
        this.contextualService.actionLessonPlay$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(() => this.nextActivity());
        this.contextualService.actionLessonRestart$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(() => this.retry());
        this.contextualService.actionLessonSummary$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(() => this.panelOpenState = true);
        this.contextualService.conditionLessonAvailable$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(this.isNextActivity && this.isMyAssignment));
        this.contextualService.conditionLessonCanRestart$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(this.displayRetryBtn));
        this.contextualService.dataLessonNextLessonName$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(this.nextActivityName));
    }

    addSummaryClassToBody(): void {
        document.getElementsByTagName('body')[0].classList.add('summary');
    }

    getActivities(): ActivityGranule[] {
        if (this.lessonsService.subLessonContentEdited.length) {
            return <ActivityGranule[]>this.lessonsService.subLessonContentEdited;
        }
        return <ActivityGranule[]>this.lessonsService.activities.filter(
            (activity) => activity?.get('metadatas')?.typology?.label !== 'EXT'
        );
    }

    removeDuplicates(activities: ActivityGranule[]): ActivityGranule[] {
        const uniqueActivities: ActivityGranule[] = [];
        const map = new Map();
        for (const activity of activities) {
            if (!map.has(activity.id)) {
                map.set(activity.id, activity);
                uniqueActivities.push(activity);
            }
        }
        return uniqueActivities;
    }

    public answers(activity: DataEntity): AnswerInterface[] {
        return (
            activity.get('reference').activity_content.answers ||
            activity.get('reference').activity_content.answers_app
        );
    }

    public getOnlyCorrectAnswer(
        itemAnswerInterfaces: AnswerInterface[]
    ): AnswerInterface[] {
        return itemAnswerInterfaces.filter((answer) => !!+answer.correct_answer);
    }

    public getOnlyCorrectFirstAnswers() {
        return this.activities.filter((activity) => this.isSuccessOnFirstAttempt(activity));
    }

    public getAnswersGradeFeedback(): string {
        const correctAnswers = this.getOnlyCorrectFirstAnswers();
        if (correctAnswers.length < GOOD_FEEDBACK_THRESHOLD) {
            return 'activities.summary_not_so_good_feedback';
        }
        return 'activities.summary_good_feedback';
    }

    public getParsedAnswer(answer: string): string[] {
        return answer && JSON.parse(answer);
    }

    public replaceFieldToFind(activity: DataEntity): string {
        return activity
            .get('reference')
            .activity_content.answer_text.replace(/\[]/g, '_ _ _ _');
    }

    public answerState(activity: TypedDataEntityInterface<ActivityGranuleAttributes>): string {
        if (this.noCorrectionRequired(activity)) {
            return ItemAnswerStateEnum.wasCorrect;
        }

        if (!this.isSuccessOnFirstAttempt(activity)) {
            return ItemAnswerStateEnum.incorrect;
        }

        return ItemAnswerStateEnum.wasCorrect;
    }

    retry(): void {
        this.communicationCenter.getRoom('progress-bar-exo').next('reset', true);
        this.communicationCenter
            .getRoom('activities')
            .next('retryCurrentActivity', this.activatedRoute.snapshot?.queryParams);
    }

    nextActivity(): void {
        this.communicationCenter.getRoom('progress-bar-exo').next('reset', true);
        this.communicationCenter.getRoom('activities').next('nextActivity', this.activatedRoute?.snapshot?.queryParams);
    }

    back(): void {
        this.communicationCenter.getRoom('progress-bar-exo').next('reset', true);
        this.communicationCenter
            .getRoom('activities')
            .next('backToLesson', {forceUrl: [], initialiseSubject: true});
    }

    public getItemAnswerInterfaceAsALine(
        onlyCorrectAnswer: AnswerInterface[]
    ): string {
        return onlyCorrectAnswer.map((a) => a.answer).join(', ');
    }

    public getDrawLineActivityImagePaths(activity: DrawLineActivityGranule): string[] {
        const imageIdentifier = activity.get('reference').config.charId;

        const dirPath = this.drawLineService.getRootImageDirectoryPath();
        return this.drawLineService
            .getTemplate(imageIdentifier)
            .steps.map((s) => s.imagePath)
            .map((i) => dirPath + i);
    }

    public isSuccessOnFirstAttempt(activity: TypedDataEntityInterface<ActivityGranuleAttributes>): boolean {
        const answerResults = this.activitiesService.answersProgressBarMultiZone.filter((answerResult: AnswerResultInterface) => +answerResult.id === +activity.id);
        const incorrectAnswers = answerResults.filter((answerResult: AnswerResultInterface) => {
            return answerResult.state !== ItemAnswerStateEnum.currentlyCorrect && answerResult.state !== ItemAnswerStateEnum.wasCorrect && !answerResult.forceStateToCurrentlyCorrect;
        });

        return answerResults.length > 0 && incorrectAnswers.length === 0;
    }

    public noCorrectionRequired(activity: TypedDataEntityInterface<ActivityGranuleAttributes>): boolean {
        return activity.get('metadatas')?.typology?.label && ACTIVITIES_WITHOUT_CORRECTION.includes(activity.get('metadatas')?.typology?.label);
    }

    public isAnswerMissing(activity: TypedDataEntityInterface<ActivityGranuleAttributes>): boolean {
        const answers = this.activitiesService.answersProgressBarMultiZone.filter((act) => +act.id === +activity.id);
        const userAnswersAttemptNotMissing =
            !!answers.length && answers.some((act: AnswerResultInterface) =>
                act.state !== ItemAnswerStateEnum.missing || act.forceStateToCurrentlyCorrect === true
            );
        // il peut y avoir plusieurs essais selon l'activité et on veut savoir s'il a passé l'activité sans donner de réponse
        return (!answers.length || !userAnswersAttemptNotMissing);
    }

}
