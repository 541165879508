import {Component} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {BaseActivityComponent} from '../base-activity.component';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '../../activities.service';
import {LessonsService} from '../../lessons/services/lessons.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {Observable, of} from 'rxjs';
import {VideoInfo} from 'js-video-url-parser/lib/urlParser';
import urlParser from 'js-video-url-parser';
import {LessonNavigationService} from '../../lesson-navigation.service';
import {ContextualService} from '@modules/activities/core/services/contextual.service';
import {MediaActivityGranule} from '@modules/activities/core/models/activities/typologies/media-activity.granule';

@Component({
    selector: 'app-media',
    templateUrl: './media.component.html'
})
export class MediaComponent extends BaseActivityComponent<MediaActivityGranule>{

    // TODO corriger le typage de media qui ne semble pas fonctionner correctement ou supprimer le component
    public media: any;
    public urlMedia: SafeResourceUrl;
    public videoUrl: boolean;

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected activitiesService: ActivitiesService,
        protected lessonsService: LessonsService,
        public sanitizer: DomSanitizer,
        protected communicationCenter: CommunicationCenterService,
        protected contextualService: ContextualService,
        protected lessonNavigationService: LessonNavigationService
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, contextualService, lessonNavigationService);
    }

    protected setContentData(activityAttributes): void {
        // todo, au jour ou j'ecrit ces ligne, on connait pas le format de reference de ce composant
        this.media = activityAttributes.reference;
        if (this.media && this.media.url){
            const parsed: VideoInfo | undefined = urlParser.parse(this.media.url);

            if (parsed || this.activitiesService.settings.urlVideoException.some((urlException) => this.media.url.includes(urlException))) { // check if video url
                this.videoUrl = true;
                this.urlMedia = this.sanitizer.bypassSecurityTrustResourceUrl(
                    this.activitiesService.getVideoLinkInfo(this.media.url));
            } else {
                this.videoUrl = false;
                this.urlMedia = this.media.url;
            }
        }
    }

    protected reset(resetAllSubscribe = false, type = null): Observable<boolean> {
        this.urlMedia = null;
        return super.reset(resetAllSubscribe, type);
    }

    public get isImage(): boolean {
        return this.media && this.media.type === 'image';
    }

    public get isVideo(): boolean {
        return this.media && this.media.type === 'video';
    }

    public get isAudio(): boolean {
        return this.media && this.media.type === 'audio';
    }

    public get isDocument(): boolean {
        return this.media && this.media.type === 'document';
    }

    public get isUrl(): boolean {
        return !!this.urlMedia;
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected  getGrade(): {oldGrade: number, newGrade: number} {
        throw new Error('Method not implemented.');
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }


    protected validate(): void {
        throw new Error('Method not implemented.');
    }
}
