import {ChangeDetectorRef, Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities';
import {CommunicationCenterService} from '@modules/communication-center';
import {AnswerInterface} from '@modules/activities/core/models/answer.interface';
import {LessonNavigationService} from '@modules/activities/core/lesson-navigation.service';
import {FullscreenService} from 'fuse-core/services/fullscreen.service';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {QcuBaseComponent} from '@modules/activities/core/player-components/qcu-base/qcu-base.component';
import {missingRandomizeTools} from '@modules/activities/core/player-components/what-is-missing/missing-randomize-tools';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {ContextualService} from '@modules/activities/core/services/contextual.service';
import {ButtonComponentConfigInterface} from '@modules/activities/core/models';

@Component({
    selector: 'app-what-is-missing',
    templateUrl: './what-is-missing.component.html',
})
/**
 * this component extend qcu be carefull to not implement ngoninit or other element that can overide
 * the default behaviour of parent class without reason
 */
export class WhatIsMissingComponent extends QcuBaseComponent {
    public start = false;
    public availableAnswersForButton: AnswerInterface[] = [];
    public hideElement: boolean;

    constructor(protected activatedRoute: ActivatedRoute,
                protected activitiesService: ActivitiesService,
                public config: LessonsConfigurationService,
                public fullscreenService: FullscreenService,
                protected lessonsService: LessonsService,
                protected communicationCenter: CommunicationCenterService,
                protected contextualService: ContextualService,
                protected ref: ChangeDetectorRef,
                protected lessonNavigationService: LessonNavigationService
    ) {
        super(activatedRoute, activitiesService, config, fullscreenService, lessonsService, communicationCenter, contextualService, ref, lessonNavigationService);
    }

    protected initialize() {
        this.start = false;
        this.hideElement = false;
        this.answersSelected = [];
        this.availableAnswersForButton = [];
        super.initialize();
        this.availableAnswers.forEach((answer) => {
            answer.state = ItemAnswerStateEnum.pristine;
            answer.correct_answer = false;
        });
        if (this.displayForSummary) {
            this.start = true;
        }
    }


    /**
     * set the order of answers and of buttons
     * @private
     */
    private setAnswersAndButtonOrder(): void {
        const answers = missingRandomizeTools.shuffleAnswers(this.availableAnswers, this.availableAnswersForButton, true);
        this.availableAnswers = answers.availableAnswers;
        this.availableAnswersForButton = answers.availableAnswersForButton.map((availableAnswer) => {
            if (this.displayForSummary && availableAnswer.correct_answer) {
                availableAnswer.state = ItemAnswerStateEnum.currentlyCorrect;
            } else {
                availableAnswer.state = ItemAnswerStateEnum.pristine;
            }
            return availableAnswer;
        });
    }

    public dynamicImgContainerClass(option: AnswerInterface & {state?: ItemAnswerStateEnum}, cardPosition: number): string {
        // only 3 carte and it s the last we add class to center it
        const centerLastCard = this.availableAnswers.length === 3 && cardPosition === 2 ? ' center-last-card' : '';
        const isHide = !this.showContent(option) ? ' to-hide' : ' not-to-hide';
        return option.state + isHide + centerLastCard;
    }

    /**
     * récupere dans la config de l'activité la mise en page.
     */
    public getColumnClass(): string {
        return this.answersSelected.length > 3 ? 'columns-2' : 'columns-1';
    }

    public startActivity(): void {
        // suppression pour le moment de l'étape avec les points d'intérogations
        //this.hideElement = true;
        this.setAnswersAndButtonOrder();
        this.start = true;
        /*setTimeout(() => {
            this.hideElement = false;
        }, 3000);*/
    }

    public showContent(option: AnswerInterface): boolean {
        return !this.start
            || !this.hideElement && !option.correct_answer
            || (option.correct_answer
                &&
                this.answersSelected.some((answer) =>
                    answer.id === option.id && (answer.state === ItemAnswerStateEnum.currentlyCorrect || answer.state === ItemAnswerStateEnum.wasCorrect)));
    }

    public onAction(button: ButtonComponentConfigInterface) {
        if (button.type === 'validate' && this.isAlmostOneAnswer()) {
            this.validate(null, false);
        } else {
            super.onAction(button);
        }
    }

    public isAlmostOneAnswer(): boolean {
        return this.answersSelected.filter(a => a.select === true).length > 0;
    }

    public classToApplyOnValidateButton(): string {
        return this.answersSelected?.find(a => a.select === true)?.state;
    }

    /**
     * is answer available selected
     * @param option
     */
    public isSelected(option: AnswerInterface) {
        return !!option && this.answersSelected[0]?.id === option?.id;
    }

    public classToApplyOnAvailableAnswer(option): string {
        return this.answersSelected?.find(a => option.id === a.id)?.state || option.state;
    }
}