import {Observable, of, ReplaySubject} from 'rxjs';
import {AfterViewInit, Component, ElementRef, NgZone, ViewChild} from '@angular/core';
import {BaseActivityComponent} from '../base-activity.component';
import * as paper from 'paper';
import * as _ from 'lodash-es';
import {AnswerResultInterface} from '../../models';
import {skip} from 'rxjs/operators';
import {DrawLineStepInterface} from '../../models/draw-line-step.interface';
import {DrawLineDataInterface} from '../../models/draw-line-data.interface';
import {DrawLineService} from '@modules/activities/core/services/draw-line.service';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '../../activities.service';
import {LessonsService} from '../../lessons/services/lessons.service';
import {CommunicationCenterService} from '@modules/communication-center/index';
import {UserDrawing} from './models/user.drawing';
import {ActivityGranuleAttributes} from '@modules/activities/core/models/activities/activity-granule.attributes';
import {answerStatusEnum} from '@modules/activities/core/models/answer-status.enum';
import {LessonNavigationService} from '../../lesson-navigation.service';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {ContextualService} from '@modules/activities/core/services/contextual.service';
import {AnswersContent} from 'app/@modules/activities/core/models/activities/activity-contents';
import {DrawLineActivityConfigInterface, DrawLineActivityGranule} from '@modules/activities/core/models/activities/typologies/draw-line-activity.granule';

const CANVAS_WIDTH = 325;
const CANVAS_HEIGHT = 325;
// Width and height of the background or step images in pixels (all the image have to be squared and at the same size)
const INITIAL_IMAGE_SIZE = 1000;
// the order checkpoints are invisible
const HOW_MANY_CHECKPOINTS_AT_TIME = 4;
// The color of the user path during the drag action
const USER_ACTIVE_PATH_COLOR = 'rgb(137,227,12)';
// The color of the user paths at the end of steps
const USER_DISABLED_PATH_COLOR = 'rgb(2,83,128)';

const USER_PATH_WIDTH = 20;
// Color of a checkpoint
const CHECKPOINT_COLOR = 'rgb(255,255,255)';
// Size of the cursor
const CURSOR_RADIUS = 45;
// border are included in radius
const CURSOR_BORDER_WIDTH = 10;
const CURSOR_COLOR = 'rgb(254,213,1)';
const CURSOR_TRANSPARENCY = 0.20;
// Taille du diamètre de la hit box du curseur
const CURSOR_TOLERANCE_RADIUS = CURSOR_RADIUS - CURSOR_BORDER_WIDTH;
const CURSOR_TOLERANCE_RADIUS_LAST_CHECKPOINT = CURSOR_TOLERANCE_RADIUS / 3;

const SHOW_ERRORS_AT_THE_END = false;
const SHOW_BACKGROUND_AT_THE_END = true;

// Combien de temps entre le visuel final et le lancement de l'activité suivante
const NEXT_ACTIVITY_DELAY = 1500;

@Component({
    selector: 'app-draw-line',
    templateUrl: './draw-line.component.html'
})
export class DrawLineComponent
    extends BaseActivityComponent<DrawLineActivityGranule>
    implements AfterViewInit {
    public canvasWidth = CANVAS_WIDTH;
    public canvasHeight = CANVAS_HEIGHT;

    @ViewChild('canvasElement') canvasElement: ElementRef;
    private scope: paper.PaperScope;
    private project: paper.Project;
    private backgroundRaster: paper.Raster;
    private lastDirection = 0;
    private data: DrawLineDataInterface;
    private cursor: paper.Group;
    private currentStep: DrawLineStepInterface;
    private tool: paper.Tool;
    private targetedCheckpointIndex: number;
    private checkpoints: paper.Path.Circle[] = [];
    private userDrawing: UserDrawing = null;
    private currentStepIndex: number;
    // private editorData: any = []; // useless for debug
    private rasters: paper.Raster[] = [];
    private isFinished = false;
    private allThingsTheUserHasDrawing: paper.Item[] = [];
    // this is a clone of the user drawing from start to last checkpoint
    private userDrawingToLastCheckpointClone: UserDrawing = null;
    private cursorDefaultCheckpointHitBox: paper.Path;
    private cursorFinalCheckpointHitBox: paper.Path;

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected activitiesService: ActivitiesService,
        protected lessonsService: LessonsService,
        protected communicationCenter: CommunicationCenterService,
        protected contextualService: ContextualService,
        private drawLineService: DrawLineService,
        private zone: NgZone,
        protected lessonNavigationService: LessonNavigationService
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, contextualService, lessonNavigationService);
    }


    ngAfterViewInit(): void {

        this.zone.runOutsideAngular(() => {
            this.scope = new paper.PaperScope(); // Ne fonctionnera pas sans
            this.project = new paper.Project(this.canvasElement.nativeElement); // lie le canvas à paperjs
            this.tool = new paper.Tool(); // pour gérer les interaction a la souris

            this.loadImages().subscribe(() => {
                // distance entre les points d'un path (plus elle est grande, plus on a une impression de lag
                this.tool.fixedDistance = Math.floor(CANVAS_WIDTH / 100);
                this.tool.onMouseDown = (e: paper.ToolEvent) => this.onMouseDown(e);

                this.backgroundRaster = this.rasters[0];

                this.initStep(0);

                this.scope.view.onResize = () => {
                    this.resizeImages();
                };

                this.cursorDefaultCheckpointHitBox = new paper.Path.Circle({
                    position: this.cursor.position,
                    radius: CURSOR_TOLERANCE_RADIUS
                });

                this.cursorFinalCheckpointHitBox = new paper.Path.Circle({
                    position: this.cursor.position,
                    radius: CURSOR_TOLERANCE_RADIUS_LAST_CHECKPOINT
                });
            });
        });
    }

    /**
     * Not implemented here because the success or wrong state is not evaluated as usual
     * @protected
     */
    protected checkAnswer(): void {
        throw new Error('Not implemented');
    }

    /**
     * Not implemented here because there is way now to evaluate the user score.
     * @protected
     */
    protected getGrade(): { oldGrade: number; newGrade: number } {
        return {newGrade: 0, oldGrade: 0};
    }

    /**
     * Not implemented here because there is no save in this activity
     * @protected
     */
    protected reviewAnswer(): void {
        throw new Error('Not implemented');
    }

    /**
     * Not implemented here because there is nothing to save, each time we load this activity, the user has to redo from start
     * @protected
     */
    protected saveAnswer(): Observable<any> {
        return of(null);
    }

    /**
     * Not implemented here because there is no save in this activity
     * @protected
     */
    protected seeAnswerSolution(): void {
        throw new Error('Not implemented');
    }

    /**
     * Not implemented here because there is no save in this activity
     * @protected
     */
    protected setAnswer(): void {
        return;
    }

    protected setContentData(attributes: ActivityGranuleAttributes<AnswersContent, DrawLineActivityConfigInterface>): void {
        // On a commencé l'exercice rien qu'en le chargeant
        this.answerStatus = answerStatusEnum.missing;
        const templateId = attributes.reference.config.charId;
        this.data = this.drawLineService.getTemplate(templateId);
        this.instruction = attributes.reference.instruction;
    }

    private loadImages(): Observable<void> {
        const subject = new ReplaySubject<void>(1);
        this.rasters.push(
            new paper.Raster({source: this.drawLineService.getRootImageDirectoryPath() + this.data.backgroundImagePath}),
            ...this.data.steps.map(s =>
                new paper.Raster({
                    source: this.drawLineService.getRootImageDirectoryPath() + s.imagePath
                })
            ));

        this.rasters.forEach(r => {
            r.onLoad = () => {
                r.fitBounds(this.project.view.bounds);
                r.position = this.project.view.center;

                subject.next();
            };
        });

        return subject.pipe(skip(this.rasters.length - 1));
    }

    private onMouseDown(e: paper.ToolEvent): void {
        if (this.isFinished === false && this.cursor.contains(e.point)) {
            if (this.isLineStep()) {
                this.startDrawing(e);
            } else {
                this.oneDotDrawing(e);
            }
        }
    }

    private initStep(stepIndex: number): void {
        this.userDrawingToLastCheckpointClone = null;
        this.userDrawing = null;
        this.currentStepIndex = stepIndex;
        this.currentStep = this.data.steps[stepIndex];
        this.rasters.forEach((r, i) => r.opacity = (i === 0 || i === stepIndex + 1) ? 1 : 0);
        this.checkpoints = this.currentStep.checkpoints.map((d) =>
            new paper.Path.Circle({
                radius: 7,
                fillColor: new paper.Color(CHECKPOINT_COLOR),
                position: new paper.Point({
                    x: (d.x * CANVAS_WIDTH / INITIAL_IMAGE_SIZE) + 7 / 2 / 2,
                    y: (d.y * CANVAS_HEIGHT / INITIAL_IMAGE_SIZE) + 7 / 2 / 2
                })
            })
        );

        if (this.isLineStep()) {
            this.initLineStep();
        } else {
            this.initOneCheckpointStep();
        }
    }

    private startDrawing(ev: paper.ToolEvent): void {
        if (this.userDrawing === null) {
            this.resetUserDrawing(ev.point);
        }
        this.userDrawing.lastPoint = ev.point;
        this.cursor.bringToFront();
        this.tool.onMouseDrag = (e: paper.ToolEvent) => {
            if (this.isUserIsCorrect(e)) {
                this.onMouseDragCorrect(e);
            } else {
                this.onMouseDragError();
            }
        };
    }

    private resetUserDrawing(position: paper.Point): void {
        const path = new paper.Path({
            fillColor: new paper.Color(USER_ACTIVE_PATH_COLOR),
            segments: [position]
        });

        const startingCircle = new paper.Path.Circle({
            fillColor: new paper.Color(USER_ACTIVE_PATH_COLOR),
            radius: USER_PATH_WIDTH / 2,
            position: position
        });

        const endingCircle = startingCircle.clone();

        this.userDrawing = new UserDrawing({
            start: startingCircle,
            end: endingCircle,
            path: path,
            angles: []
        });

        this.userDrawing.apply(i => this.allThingsTheUserHasDrawing.push(i));
    }

    private onMouseDragError(): void {
        this.updateProgressBar(false, false);
        this.resetVisualToLastCheckpoint();
        this.tool.onMouseDrag = null;
    }

    private onMouseDragCorrect(e: paper.ToolEvent): void {
        this.moveCursor(e);
        this.updateCurrentPath(e);
        if (this.isUserIsOverCheckpoint()) {
            this.targetedCheckpointIndex++;
            this.updateCheckpointsColors();
            this.backupCurrentUserDrawing();
        }
        this.userDrawing.end.position = e.point;
        this.checkStepIsComplete();

        // la fin a peut etre été lancé entre deux mais il reste plus simple de mettre la mise a jour de curseur apres.
        if (this.isFinished !== true) {
            this.cursor.bringToFront();
            this.updateCursorDirection();
        }
    }

    private moveCursor(e: paper.ToolEvent): void {
        this.cursor.position = e.point;
    }

    private isUserIsOverCheckpoint(): boolean {
        const isLastCheckpoint = this.targetedCheckpointIndex === this.checkpoints.length - 1;
        const hitBox = isLastCheckpoint ? this.cursorFinalCheckpointHitBox : this.cursorDefaultCheckpointHitBox;
        hitBox.position = this.cursor.position;
        return hitBox.contains(this.checkpoints[this.targetedCheckpointIndex].position);
    }

    private updateCheckpointsColors(): void {
        const getAlpha = (index: number) => {
            if (index < this.targetedCheckpointIndex) {
                return 0;
            }
            return 1 - (index - this.targetedCheckpointIndex) / HOW_MANY_CHECKPOINTS_AT_TIME;
        };

        this.checkpoints.forEach((d, i) => {
            d.fillColor.alpha = getAlpha(i);
        });
    }

    private updateCurrentPath(e: paper.ToolEvent): void {
        const lastPoints = this.getTopBottomLeftRightPoints(this.userDrawing.lastPoint, e.delta);
        const middlePoints = this.getTopBottomLeftRightPoints(e.middlePoint, e.delta);
        const currentPoints = this.getTopBottomLeftRightPoints(e.point, e.delta);

        const circle = new paper.Path.Circle({
            fillColor: new paper.Color(USER_ACTIVE_PATH_COLOR),
            radius: USER_PATH_WIDTH / 2,
            position: e.middlePoint
        });
        this.userDrawing.angles.push(circle);
        this.allThingsTheUserHasDrawing.push(circle);

        this.userDrawing.path.add(lastPoints.top);
        this.userDrawing.path.add(middlePoints.top);
        this.userDrawing.path.add(currentPoints.top);
        this.userDrawing.path.insert(0, lastPoints.bottom);
        this.userDrawing.path.insert(0, middlePoints.bottom);
        this.userDrawing.path.insert(0, currentPoints.bottom);

        this.userDrawing.lastPoint = e.point;
    }

    private getTopBottomLeftRightPoints(point: paper.Point, deltaPoint: paper.Point): { top: paper.Point, bottom: paper.Point } {
        const _deltaPoint = _.cloneDeep(deltaPoint);
        _deltaPoint.angle += 90;
        const margin = USER_PATH_WIDTH / 2;
        // valeur en dure parce que je n'ai pas trouvé l'algo pour calculer la marge effective
        const factor = margin / 5 + 0.75;
        const top = new paper.Point(point);
        top.x += (_deltaPoint.x * factor);
        top.y += (_deltaPoint.y * factor);
        const bottom = new paper.Point(point);
        bottom.x -= (_deltaPoint.x * factor);
        bottom.y -= (_deltaPoint.y * factor);

        return {top, bottom};
    }

    private checkStepIsComplete(): void {
        if (this.targetedCheckpointIndex >= this.checkpoints.length) {
            this.launchNextStep();
        }
    }

    private launchNextStep(): void {
        this.allThingsTheUserHasDrawing.forEach(p => p.fillColor = new paper.Color(USER_DISABLED_PATH_COLOR));
        this.tool.onMouseDrag = null;
        if (this.currentStepIndex < this.data.steps.length - 1) {
            this.updateProgressBar(true, false);
            this.initStep(this.currentStepIndex + 1);
        } else {
            this.launchFinishState();
            this.updateProgressBar(true, true);
            // On a forcément fini l'exercice en success, il n'est pas possible de finir l'exercice autrement.
            this.answerStatus = answerStatusEnum.correct;
        }
    }

    private isUserIsCorrect(e: paper.ToolEvent): boolean {
        const rasterFriendlyPosition = new paper.Point({
            x: e.point.x * INITIAL_IMAGE_SIZE / CANVAS_WIDTH,
            y: e.point.y * INITIAL_IMAGE_SIZE / CANVAS_HEIGHT
        });

        const colorUnderUserPointer = this.backgroundRaster.getPixel(rasterFriendlyPosition).toCSS(false);
        return this.currentStep.validColors.map(vc => new paper.Color(vc).toCSS(false)).includes(colorUnderUserPointer);
    }

    private resetVisualToLastCheckpoint(): void {
        this.restoreUserDrawingToTheLastCheckpoint();
        if (this.userDrawing === null) {
            this.resetUserDrawing(this.checkpoints[0].position);
        }
        const topPoint = this.userDrawing.path.firstSegment.point;
        const bottomPoint = this.userDrawing.path.lastSegment.point;
        const middlePoint = new paper.Point(
            (topPoint.x + bottomPoint.x) / 2,
            (topPoint.y + bottomPoint.y) / 2
        );
        this.cursor.position = middlePoint;
        this.updateCursorDirection();
        this.userDrawing.end.position = middlePoint;
        this.cursor.bringToFront();
    }

    private launchFinishState(): void {
        this.isFinished = true;
        this.cursor.remove();
        this.tool.onMouseDrag = null;
        this.tool.onMouseDown = null;

        this.allThingsTheUserHasDrawing.forEach(thing => thing.fillColor = new paper.Color(USER_ACTIVE_PATH_COLOR));

        if (SHOW_ERRORS_AT_THE_END) {
            this.allThingsTheUserHasDrawing.forEach(i => i.opacity = 1);
        }

        // @ts-ignore comme c'est une constante le linter considère que ça ne sert a rien de tester. Mais on garde ça au chaud pour plus tard.
        if (SHOW_BACKGROUND_AT_THE_END === false) {
            this.rasters.forEach(r => r.opacity = 0);
        }

        this.zone.run(() => {
            setTimeout(() => {
                // Run this code inside Angular's Zone and perform change detection
                this.doAction('next', ['save']);
            }, NEXT_ACTIVITY_DELAY);
        });
    }

    private initializeCursor(withPointer: boolean): void {
        const outsideCursorColor = new paper.Color(CURSOR_COLOR);
        const insideCursorColor = new paper.Color(outsideCursorColor);
        insideCursorColor.alpha = CURSOR_TRANSPARENCY;

        const circleSize = CURSOR_RADIUS;
        const circleBorder = CURSOR_BORDER_WIDTH;
        const circleRayon = circleSize / 2;

        const circle = new paper.Path.Circle({
            radius: circleSize - circleBorder,
            fillColor: insideCursorColor,
            strokeColor: outsideCursorColor,
            strokeWidth: circleBorder
        });

        if (withPointer) {
            this.lastDirection = 0;
            const pointerHeight = CURSOR_BORDER_WIDTH;
            const topPointerPosition = [0, -1 * (circleSize + pointerHeight)];
            const leftPointerPosition = [-circleRayon, -1 * (circleRayon + circleBorder)];
            const rightPointerPosition = [circleRayon, -1 * (circleRayon + circleBorder)];

            const pointer = new paper.Path({
                segments: [topPointerPosition, leftPointerPosition, rightPointerPosition],
                fillColor: outsideCursorColor,
                closed: true
            });
            this.cursor = new paper.Group([circle, pointer]);
        } else {
            this.cursor = new paper.Group([circle]);
        }

        this.cursor.bringToFront();
    }

    private updateCursorDirection(): void {
        const targetedCheckpoint = this.checkpoints[this.targetedCheckpointIndex];
        const targetAngle = (Math.atan2(targetedCheckpoint.position.y - this.cursor.position.y, targetedCheckpoint.position.x - this.cursor.position.x) * (180 / Math.PI)) + 90;
        const clampedTargetAngle = (targetAngle + 360) % 360;
        this.cursor.rotation = 360 - this.lastDirection;
        this.lastDirection = clampedTargetAngle;
        this.cursor.rotation = clampedTargetAngle;
    }

    private resizeImages(): void {
        this.rasters.forEach(r => {
            r.fitBounds(this.project.view.bounds);
            r.position = this.project.view.center;
        });
    }

    private backupCurrentUserDrawing(): void {
        this.userDrawingToLastCheckpointClone = this.userDrawing.clone()
            .apply(item => item.opacity = 0)
            .apply(item => this.allThingsTheUserHasDrawing.push(item));
    }

    private restoreUserDrawingToTheLastCheckpoint(): void {
        this.userDrawing.apply(item => item.opacity = 0);
        if (!!this.userDrawingToLastCheckpointClone) {
            this.userDrawing = this.userDrawingToLastCheckpointClone.clone()
                .apply(item => this.allThingsTheUserHasDrawing.push(item))
                .apply(item => item.opacity = 1);
        } else {
            this.userDrawing = null;
        }
    }

    private updateProgressBar(isAnswerCorrect: boolean, isLast: boolean): void {
        this.zone.run(() => {
            const answerResult: AnswerResultInterface = {
                id: +this.activity.id,
                state: isAnswerCorrect ? ItemAnswerStateEnum.currentlyCorrect : ItemAnswerStateEnum.incorrect,
                isLast
            };

            super.manageProgressBarEventToSend(answerResult);
        });
    }

    /**
     * If the step is a list of checkpoints to follow, it's a line step
     * @private
     */
    private initLineStep(): void {
        if (!!this.cursor) {
            this.cursor.remove();
        }
        this.initializeCursor(true);
        this.targetedCheckpointIndex = 1;
        this.cursor.position = new paper.Point(this.checkpoints[0].position);
        this.updateCheckpointsColors();
        this.updateCursorDirection();
    }

    /**
     * If the step as only one checkpoint, the step is valid juste by clicking on the cursor
     * @private
     */
    private initOneCheckpointStep(): void {
        if (!!this.cursor) {
            this.cursor.remove();
        }
        this.initializeCursor(false);
        this.cursor.position = new paper.Point(this.checkpoints[0].position);
    }

    private isLineStep(): boolean {
        return this.currentStep.checkpoints.length > 1;
    }

    /**
     * it's like the line drawing but for only step
     * @param e
     * @private
     */
    private oneDotDrawing(e: paper.ToolEvent): void {
        const circle = new paper.Path.Circle({
            fillColor: new paper.Color(USER_ACTIVE_PATH_COLOR),
            radius: USER_PATH_WIDTH / 2,
            position: e.point
        });
        this.allThingsTheUserHasDrawing.push(circle);
        this.checkpoints.forEach(d => d.remove());
        this.launchNextStep();
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }

    protected validate(): void {
        throw new Error('Method not implemented.');
    }
}