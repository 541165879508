import {takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {ConfigurationService} from '../../configuration.service';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {defaultApiURL} from 'app/settings';
import {TranslateService} from '@ngx-translate/core';
import {localizedTime} from 'app/shared/utils/datetime';
import {ChaptersService} from 'fuse-core/services/chapters.service';

@Component({
    selector: 'app-configuration-data',
    templateUrl: './configuration-data.component.html',
    styleUrls: ['./configuration-data.component.scss']
})
export class ConfigurationDataComponent implements OnInit, OnDestroy {
    public methodControl: UntypedFormControl;
    public idsList: UntypedFormControl;

    private unsubscribeInTakeUntil = new Subject<void>();
    public fileUploaded = false; // use for say to component upload-exercice that upload is done
    public uploaded = false;
    public error = '';
    public uploadedFid: number;
    public canMigrate: boolean;
    public lastImport: string;
    public startImport: string;
    public endImport: string;
    public synchroLabel: string;
    public licensingMethods: object[];

    constructor(public configurationService: ConfigurationService,
                private chaptersService: ChaptersService,
                private http: HttpClient,
                private translate: TranslateService) {
    }

    ngOnInit(): any {
        this.chaptersService.getParentChapters().pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((entities) => {
                this.licensingMethods = entities.map((entity) => {
                    return {
                        id: entity.id,
                        label: entity.get('name')
                    };
                });
            });

        this.methodControl = new UntypedFormControl('', [Validators.required]);
        this.idsList = new UntypedFormControl('');

        this.configurationService.getMigrations().pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe((entities) => {
            this.canMigrate = !entities.find((entity) => entity.id === 'migration_running').get('value');
            this.endImport = localizedTime(entities.find((entity) => entity.id === 'migration_end_date').get('value'));
            this.startImport = localizedTime(entities.find((entity) => entity.id === 'migration_start_date').get('value'));

            if (this.canMigrate) {
                this.lastImport = this.endImport;
            } else {
                this.lastImport = this.startImport;
            }

            this.getLabelBySynchroState();
        });
        this.getLabelBySynchroState();
    }

    public synchronize(): void {
        this.canMigrate = false;
        this.configurationService.synchronize(this.idsList.value);
    }

    public getLabelBySynchroState(): void {
        let key = 'configuration.data.synchro.editic.lastsynchronising';
        if (!this.canMigrate) {
            key = 'configuration.data.synchro.editic.synchronising';
        }
        this.translate.get(key).subscribe((res: string) => {
            this.synchroLabel = res;
            if (this.canMigrate) {
                this.synchroLabel = res.replace('{{endDate}}', this.endImport);
                this.synchroLabel = this.synchroLabel.replace('{{startDate}}', this.startImport);
            } else {
                this.synchroLabel = res.replace('{{time}}', this.lastImport);
            }
        });
    }

    uploadFile(file: File, format = ['csv', 'zip'], path = 'api/file-upload'): void {
        this.error = '';
        this.fileUploaded = false;
        if (file && format.indexOf(file.name.slice((file.name.lastIndexOf('.') - 1 >>> 0) + 2)) !== -1) {
            const formData = new FormData();
            formData.append('file', file);
            this.http
                .post<any>(defaultApiURL + path, formData, {headers: {'access-token': this.userAccessToken}})
                .subscribe(res => {
                    this.fileUploaded = true;
                    this.uploaded = true;
                    this.uploadedFid = +res['data'][0][0]['id'];
                }, error => {
                    if (error.status === 422 && error.error.title){
                        this.error = error.error.title;
                    } else {
                        this.error = error;
                    }
                });
        }
    }

    onFileInput(evt: Event): void {
        this.uploadFile(evt.target['files'][0]);
    }

    importLicense(): void {
        this.configurationService.importLicense(this.uploadedFid, this.methodControl.value.id);
    }

    ngOnDestroy(): void {
        if (this.unsubscribeInTakeUntil) {
            this.unsubscribeInTakeUntil.next();
            this.unsubscribeInTakeUntil.complete();
        }
    }

    get userAccessToken(): any {
        const access = JSON.parse(localStorage.getItem('http_accessToken'));

        return access;
    }

}
