import { Component, OnInit } from '@angular/core';
import {
    GraphInclusiveAuthorizationService
} from "@modules/graph-inclusive/core/services/graph-inclusive-authorization.service";

@Component({
  selector: 'app-single-graph-view',
  templateUrl: './single-graph-view.component.html',
})
export class SingleGraphViewComponent implements OnInit {

  constructor(
      private graphInclusiveAuthorizationService: GraphInclusiveAuthorizationService
  ) { }

  ngOnInit(): void {
  }

    public get rolesCanShowBannerInfo(): boolean {
        return this.graphInclusiveAuthorizationService.rolesCanShowBannerInfo;
    }

}
