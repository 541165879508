export const mainMenu = {
    displayMenu: {
        learner: {
            level0: [
                'home',
                {
                    name: 'followed-tab',
                    translate: {
                        parent: {
                            learner: 'assignment.title_my_activity',
                            default: 'assignment.title',
                        },
                    },
                    icon: 'lessons',
                },
                // followed with tab include followed list and diary log use only in mathia for moment
                'graph-inclusive-single-own-progress',
                'corpus',
                {
                    name: 'tools',
                    children: ['notepadList', 'mindmapList', 'bdList'],
                },
                {
                    name: 'informations-legales',
                    class: ['menu-break'],
                },
            ],
        },
        trainer: {
            level0: [
                'home',
                {
                    name: 'parameter',
                    translate: {
                        parent: {
                            learner: 'generic.my_classes_learner',
                            default: 'navigation.groups-management',
                        },
                        childs: {
                            'param-child-groups': {
                                learner: 'generic.my_class',
                                default: 'groups-management.classes',
                            },
                            'param-child-workgroups': {
                                learner: 'generic.my_workgroups',
                                default: 'groups-management.workgroups',
                            },
                        },
                    },
                },
                {
                    name: 'forFurther',
                    children: ['ressources_inclusive', 'ressources_supplementaires',],
                },
                'lessonsDeployable',
                'boussole',
                'corpus',
                {
                    name: 'tools',
                    children: [
                        'notepadList',
                        'mindmapList',
                        'bdList'
                    ],
                },
                {
                    name: 'followed-tab',
                    translate: {
                        parent: {
                            learner: 'assignment.title_my_activity',
                            default: 'assignment.title',
                        },
                    },
                },
                'graph-inclusive',
                'ideasWall',
                {
                    name: 'informations-legales',
                    class: ['menu-break'],
                },
                'feedback',
            ],
        },
        default: {
            level0: [
                'home',
                {
                    name: 'parameter',
                    translate: {
                        parent: {
                            learner: 'generic.my_classes_learner',
                            default: 'navigation.groups-management',
                        },
                        childs: {
                            'param-child-groups': {
                                learner: 'generic.my_class',
                                default: 'groups-management.classes',
                            },
                            'param-child-workgroups': {
                                learner: 'generic.my_workgroups',
                                default: 'groups-management.workgroups',
                            },
                        },
                    },
                },
                'lessonsDeployable',
                {
                    name: 'followed-tab',
                    translate: {
                        parent: {
                            learner: 'assignment.title_my_activity',
                            default: 'assignment.title',
                        },
                    },
                },
                // followed with tab include followed list and diary log use only in mathia for moment
                {
                    name: 'informations-legales',
                    class: ['menu-break'],
                },
                'feedback',
            ],
        },
    },
};