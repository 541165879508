import {TypologyLabel} from '@modules/activities/core/models/typology.label';
import {ActivityContent, AnswersContent} from 'app/@modules/activities/core/models/activities/activity-contents';
export interface ActivityReference<U, T extends ActivityContent = AnswersContent> {
    wording: string;
    wordingAudio: string;
    activity_content: T;
    instruction: string;
    instructionAudio: string;
    config: U;
    feedback?: string;
    finalFeedback?: string;
    typology?: {
        label: TypologyLabel;
    };
}
