import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-activity-edition-preview-add-media',
  templateUrl: './activity-edition-preview-add-media.component.html',
  styleUrls: ['./activity-edition-preview-add-media.component.scss']
})
export class ActivityEditionPreviewAddMediaComponent {
    @Input() mediaType: string | null = null;
}
